import React from "react";
import ProfileArtist from "./components/ProfileArtist";
import "./components/artist.css";
import TopSongs from "./components/TopSongs";
export default function ArtistPage({}) {
  return (
    <>
      <ProfileArtist />
      <TopSongs />
    </>
  );
}
