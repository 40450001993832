import React from "react";
import { dummyPodcast } from "../data/podcastData";
import { Icon } from "../../components/Component";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import BreadCrumbs from "./Breadcrumbs/BreadCrumbs";

export default function PodcastList() {
  return (
    <>
      <div className="allPodcastViewer gx-1 gy-2 p-3 row container-fluid">
        <div className="d-flex justify-content-between">
          <h3 className="rubik-font">Latest Podcasts</h3>
          <div>
            <BreadCrumbs />
          </div>
          {/* <p className="showalltext text-dark">Show all</p> */}
        </div>
        {dummyPodcast.map((podcast, index) => (
          <div className="col-md-3 col-sm-4 col-xxl-2">
            <div key={index} className="podcast-entryPoint me-2 border">
              <figure className="podcast-img-parent">
                <img src={podcast.imageUrl} alt="Podcast" className="img-fluid rounded-3" />
                <div className="song-meta-details">
                  <div className="livecast-play">
                    <Icon name="play-fill" className="fs-1" />
                  </div>
                  <div className="entry-metas ms-2">
                    <span className="entry-count">{podcast.author}</span>
                    <span className="ce-date entry-meta-single fw-bold">{podcast.date}</span>
                  </div>
                </div>
              </figure>
              <div>
                <span className="entry-icon text-danger d-flex align-items-center">
                  <Icon name="book-read" className="fs-6" />
                  <small className="ms-1 fw-bold rubik-font" style={{ fontSize: "13px" }}>
                    Historical
                  </small>
                </span>
                <h3 className="podcast-title mt-1">{podcast.title}</h3>
              </div>
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-end">
          <Pagination aria-label="Page navigation example" className="me-2">
            <PaginationItem>
              <PaginationLink
                className="page-link-prev"
                href="#prev"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <Icon name="chevrons-left" />
                <span>Prev</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {" "}
                1{" "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {" "}
                2{" "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink tag="span">
                <Icon name="more-h" />
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {" "}
                6{" "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                {" "}
                7{" "}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                className="page-link-next"
                href="#next"
                onClick={(ev) => {
                  ev.preventDefault();
                }}
              >
                <span>Next</span>
                <Icon name="chevrons-right" />
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </>
  );
}
