import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Tabs, Tab, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Img1 from "../../images/Podcast/Landingpage/new-podcast-image.jpg";
import Img2 from "../../images/Podcast/Landingpage/new-podcast-image.jpg";
import Img3 from "../../images/Podcast/Landingpage/new-podcast-image.jpg";
import Img4 from "../../images/Podcast/Landingpage/new-podcast-image.jpg";
import Img5 from "../../images/Podcast/Landingpage/new-podcast-image.jpg";
import "../../pages/components/css/style.css";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "../../components/Component";
import { getArtistDetails } from "../../http/Get";
import { followUser } from "../../http/Get";
import ProfileCard from "./Artistprofilecard";
import defaultImage from "../../assets/images/jsTree/arijit-singh.jpg";

const profileData = [
  { imageSrc: Img1, title: "Profile Title 1" },
  { imageSrc: Img2, title: "Profile Title 2" },
  { imageSrc: Img3, title: "Profile Title 3" },
  { imageSrc: Img1, title: "Profile Title 4" },
  { imageSrc: Img2, title: "Profile Title 5" },
  { imageSrc: Img3, title: "Profile Title 6" },
];

const songImages = {
  "O Maahi": Img1,
  'Tum Kya Mile(From "Rocky Aur Rani Kii Prem Kahaani")': Img2,
  "Tum Se Hi": Img3,
  "Ve Kamleya": Img4,
  Kesariya: Img5,
};

const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

function Artist() {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [isFollowing, setIsFollowing] = useState(false);
  const followMutation = useMutation({
    mutationFn: followUser,
    onSuccess: () => {
      queryClient.invalidateQueries(["getFollow"]);
      toast.success(isFollowing ? "Following" : "Unfollowing");
    },
    onError: (error) => {
      console.error("Error following user:", error);
      toast.error("Error following user.");
    },
  });

  const handleFollowClick = () => {
    if (followMutation.isLoading) return;

    const newFollowState = !isFollowing;
    setIsFollowing(newFollowState);

    const artistId = artistData?.data?.artist_data?.artist_profile?.id || id;
    followMutation.mutate(artistId);
  };

  const recentlyAddedSongs = [
    { SrNo: 1, Title: "O Maahi", AddedDate: "12-5-2024", Duration: "3:53" },
    { SrNo: 2, Title: "Tum Kya Mile", AddedDate: "13-4-2024", Duration: "4:37" },
    { SrNo: 3, Title: "Tum Se Hi", AddedDate: "22-7-2024", Duration: "5:21" },
    { SrNo: 4, Title: "Ve Kamleya", AddedDate: "28-8-2024", Duration: "4:06" },
    { SrNo: 5, Title: "Kesariya", AddedDate: "10-3-2024", Duration: "4:28" },
  ];

  const {
    isPending,
    error,
    data: artistData,
  } = useQuery({
    queryKey: ["podcast-artist", id],
    queryFn: () => getArtistDetails(id),
    staleTime: 0,
    gcTime: Infinity,
  });

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row">
          <img
            src={artistData?.data?.artist_data?.artist_profile?.cover_image}
            alt=""
            height={"300px"}
            className="object-fit-cover artist-coverimage"
          />
        </div>

        <div className="d-flex justify-content-center row position-relative py-2">
          <div className="col-md-3 text-center position-artist-image">
            <img
              src={artistData?.data?.artist_data?.artist_profile?.profile_image}
              alt=""
              className="rounded-circle object-fit-cover"
              height={"100px"}
              width={"100px"}
            />
          </div>
          <div className="col-md-6 align-content-center">
            <div className="d-flex justify-content-between">
              <h4 className="align-item-center d-flex">Name of podcaster</h4>
              <div>
                <button
                  type="button"
                  className="btn btn-warning px-2 mx-2"
                  onClick={handleFollowClick}
                  disabled={followMutation.isLoading}
                >
                  {followMutation.isLoading ? "Processing..." : isFollowing ? "Followed" : "Follow"}
                </button>
                <Icon name="upload" id="icon"></Icon>
              </div>
            </div>
            <div className="">
              <p className="text-dark text-truncate">{artistData?.data?.artist_data?.artist_profile?.artist_bio}</p>
            </div>
          </div>
        </div>

        <div>
          <Tabs defaultActiveKey="popular" id="song-tabs" className="mb-3 nav-tab">
            <Tab eventKey="popular" title="Rank">
              <table className="song-table">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Play</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody className="song-table-body">
                  {artistData?.data?.artist_data?.ranked_episodes?.slice(0, 6).map((artist) => (
                    <tr key={artist.sr_no}>
                      <td>{artist.sr_no}</td>
                      <td>
                        <img
                          src={artist.episode_image || defaultImage}
                          alt={artist.title}
                          className=""
                          width={"100px"}
                          onError={(e) => {
                            e.target.src = defaultImage;
                          }} 
                        />
                      </td>

                      <td>{artist.title}</td>
                      <td>
                        <Icon
                          name="play-circle"
                          id="playbutton"
                          style={{ cursor: "pointer" }}
                          onClick={() => console.log("Play button clicked!")}
                        />
                      </td>
                      <td>{formatDuration(artist.duration)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tab>

            <Tab eventKey="rank" title="Most Popular">
              <div className="row">
                <ProfileCard most_popular={artistData?.data?.artist_data?.most_popular_episodes} />
              </div>
            </Tab>

            <Tab eventKey="recent" title="Recently Added">
              <div>
                <table className="" style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "0px solid #ccc", padding: "8px" }}>SrNo</th>
                      <th style={{ border: "0px solid #ccc", padding: "8px" }}>Image</th>
                      <th style={{ border: "0px solid #ccc", padding: "8px" }}>Title</th>
                      <th style={{ border: "0px solid #ccc", padding: "8px" }}>Uploaded on</th>
                      <th className="duration-column" style={{ border: "0px solid #ccc", padding: "8px" }}>
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody className="recentlysong">
                    {recentlyAddedSongs.map((song, index) => (
                      <tr key={index}>
                        <td style={{ border: "0px solid #ccc", padding: "8px" }}>{song.SrNo}</td>
                        <td style={{ border: "0px solid #ccc", padding: "8px" }}>
                          <img
                            src={songImages[song.Title] || Img1}
                            alt=""
                            style={{ height: "50px", width: "50px", objectFit: "cover" }}
                          />
                        </td>
                        <td style={{ border: "0px solid #ccc", padding: "8px" }}>{song.Title}</td>
                        <td style={{ border: "0px solid #ccc", padding: "8px" }}>{song.AddedDate}</td>
                        <td className="duration-column" style={{ border: "0px solid #ccc", padding: "8px" }}>
                          {song.Duration}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Artist;
