import * as React from "react";
const FireIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={20}
    height={20}
    x={0}
    y={0}
    viewBox="0 0 512 512"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M373.499 163.809c-10.67-11.649-27.258-27.372-34.315 8.13-2.4 12.071-8.007 21.376-12.812 28.868-5.179-26.212-20.761-53.343-35.443-71.636-5.494-6.844-24.988-34.051-28.733-79.567-.559-6.793-8.628-9.983-13.675-5.401-48.232 43.784-74.706 104.002-75.689 171.795 0 0-20.084-16.929-30.995-48.423-2.938-8.481-14.308-10.099-19.241-2.6a75.358 75.358 0 0 0-2.617 4.282c-37.213 65.8-55.114 145.705-38.565 220.045 27.67 124.497 210.704 159.303 307.603 81.054 94.805-76.556 64.669-219.045-15.518-306.547z"
        style={{}}
        fill="#ed694a"
        data-original="#ed694a"
        className=""
      />
      <path
        d="M107.171 397.118c-16.357-75.135.957-155.818 37.296-222.648a123.957 123.957 0 0 1-2.629-6.894c-2.938-8.481-14.308-10.099-19.241-2.6a75.329 75.329 0 0 0-2.616 4.282c-37.213 65.8-55.114 145.705-38.565 220.045 12.621 56.786 57.569 94.9 111.92 112.149-42.4-20.539-75.604-55.748-86.165-104.334zM198.716 214.75c2.009-61.527 24.263-116.697 64.093-159.079a180.384 180.384 0 0 1-.608-6.005c-.571-6.851-8.345-10.287-13.37-5.745-46.585 42.118-74.979 101.727-75.998 172.075l8.719 6.771c4.294 3.335 10.112 3.19 14.213.135 2.447-2.074 2.798-5.204 2.951-8.152z"
        style={{}}
        fill="#d8553a"
        data-original="#d8553a"
        className=""
      />
      <path
        d="M344.242 254.818c-7.88-8.604-20.133-20.218-25.345 6.005-1.772 8.915-5.915 15.789-9.462 21.323-3.825-19.361-15.335-39.401-26.179-52.912-4.058-5.055-18.456-25.15-21.223-58.771-.413-5.018-6.372-7.373-10.1-3.989-35.625 32.339-55.18 76.819-55.905 126.891 0 0-14.835-12.504-22.893-35.766-2.17-6.264-10.569-7.459-14.212-1.92a55.755 55.755 0 0 0-1.933 3.163c-27.487 48.601-40.709 107.62-28.486 162.53 20.438 91.956 155.63 117.665 227.202 59.868 70.024-56.545 47.765-161.79-11.464-226.422z"
        style={{}}
        fill="#f4a32c"
        data-original="#f4a32c"
        className=""
      />
      <path
        d="M153.994 428.585c-10.822-53.676.387-111.271 24.152-159.231a92.26 92.26 0 0 1-5.013-11.754c-2.17-6.264-10.569-7.46-14.212-1.921a55.421 55.421 0 0 0-1.933 3.164c-27.486 48.601-40.707 107.62-28.486 162.529 9.506 42.768 43.837 71.196 85.041 83.567-29.275-14.681-52.325-40.475-59.549-76.354z"
        style={{}}
        fill="#e89528"
        data-original="#e89528"
        className=""
      />
      <path
        d="M317.534 337.904c-5.334-5.824-13.63-13.687-17.158 4.065-1.199 6.036-4.004 10.688-6.406 14.434-2.59-13.107-10.38-26.672-17.722-35.818-2.746-3.422-12.493-17.025-14.367-39.784-.28-3.397-4.313-4.992-6.837-2.7-24.116 21.892-37.353 52.002-37.844 85.897 0 0-10.043-8.465-15.497-24.211-1.469-4.24-7.154-5.049-9.62-1.3a37.63 37.63 0 0 0-1.309 2.141c-18.606 32.9-27.557 72.852-19.283 110.023 13.835 62.249 105.352 79.652 153.801 40.527 16.591-13.398 26.878-32.277 28.964-53.16 3.149-31.63-9.024-69.889-36.722-100.114z"
        style={{}}
        fill="#f4d44e"
        data-original="#f4d44e"
        className=""
      />
      <path
        d="M292.749 415.006c-2.972-3.245-7.593-7.625-9.559 2.264-.668 3.363-2.23 5.955-3.569 8.042-1.443-7.302-5.783-14.86-9.873-19.955-1.53-1.907-6.961-9.486-8.005-22.165-.156-1.893-2.404-2.78-3.809-1.505-13.435 12.197-20.811 28.972-21.084 47.856 0 0-5.595-4.716-8.634-13.489-.819-2.363-3.986-2.814-5.359-.724-.264.401-.508.803-.728 1.193-10.366 18.33-15.353 40.588-10.743 61.297 10.498 47.231 96.546 45.799 101.824-7.039 1.752-17.621-5.03-38.937-20.461-55.775z"
        style={{}}
        fill="#eae9e8"
        data-original="#eae9e8"
        className=""
      />
      <path
        d="M231.364 487.194c-4.61-18.525.377-38.436 10.743-54.832.22-.349.465-.708.728-1.067 1.374-1.869 4.541-1.466 5.359.647 3.039 7.848 8.634 12.067 8.634 12.067.225-13.88 5.253-26.48 14.488-36.586-2.065-2.86-8.383-9.756-9.574-24.229-.156-1.893-2.404-2.78-3.809-1.505-13.435 12.197-20.811 28.972-21.084 47.856 0 0-5.595-4.716-8.634-13.488-.818-2.363-3.986-2.814-5.359-.724-.264.401-.508.803-.728 1.193-10.366 18.329-15.353 40.588-10.743 61.297 4.266 19.195 21.792 30.731 41.168 33.515-10.452-5.133-18.485-13.261-21.189-24.144z"
        style={{}}
        fill="#f7e7a1"
        data-original="#f7e7a1"
        className=""
      />
      <path
        d="M184.598 68.045c21.625-17.589 21.577-50.496 0-68.045-21.625 17.588-21.578 50.496 0 68.045zM443.793 209.213c21.625-17.589 21.577-50.496 0-68.045-9.884 8.038-21.577 50.496 0 68.045zM60.785 222.606c15.585-12.677 15.551-36.392 0-49.041-7.123 5.793-15.551 36.393 0 49.041zM330.709 117.086c15.585-12.677 15.551-36.392 0-49.041-15.585 12.676-15.55 36.393 0 49.041z"
        style={{}}
        fill="#d8553a"
        data-original="#d8553a"
        className=""
      />
      <path
        d="M215.911 285.324c3.579-42.252 19.714-79.821 46.806-108.462a130.56 130.56 0 0 1-.685-6.398c-.413-5.018-6.372-7.373-10.1-3.989-28.23 25.626-46.348 58.882-53.022 96.497l-.01-.003a208.034 208.034 0 0 0-1.497 10.047.03.03 0 0 0 .004.008 191.271 191.271 0 0 0-1.381 20.343c.754.147 17.822 8.92 19.885-8.043z"
        style={{}}
        fill="#e89528"
        data-original="#e89528"
        className=""
      />
      <path
        d="M196.43 459.481c-7.355-34.725-1.091-71.755 13.461-103.84a62.744 62.744 0 0 1-8.187-15.855c-1.47-4.24-7.154-5.049-9.621-1.3a37.63 37.63 0 0 0-1.309 2.141c-18.606 32.9-27.557 72.852-19.283 110.023 6.48 29.152 29.997 48.468 58.148 56.749-16.386-10.878-28.781-26.979-33.209-47.918zM243.751 348.824c2.438-17.425 9.565-36.624 20.784-52.675-1.222-4.532-2.183-9.653-2.652-15.348-.28-3.397-4.314-4.992-6.837-2.7-15.026 13.641-25.811 30.479-31.938 49.428l-.016-.004c-.964 2.977-2.327 7.909-3.153 11.802.007-.002.014-.002.021-.005a127.163 127.163 0 0 0-2.756 24.675c13.492 1.684 24.653-1.657 26.547-15.173z"
        style={{}}
        fill="#e8c842"
        data-original="#e8c842"
        className=""
      />
    </g>
  </svg>
);
export default FireIcon;
