import React from "react";
import { BsFillPinAngleFill } from "react-icons/bs";
import { FaPlus } from "react-icons/fa"; // Import the FaPlus icon
import { Icon } from "../../components/Component";

export default function SidebarCompact({ leadText, subText, avatarShape, isPinnned, isFeatured }) {
  const defaultLeadText = "John";
  const defaultSubText = "John@doe.com";

  return (
    <div className="d-flex align-items-center mb-1 sidebarCompactParent">
      {(isPinnned || isFeatured) && (
        <div>{isFeatured ? <FaPlus className="text-primary" /> : <BsFillPinAngleFill color="#1BC558" />}</div>
      )}

      <div className={`align-items-center d-flex ${isPinnned ? "ms-1" : ""} position-relative`}>
        <span className="fs-6">{leadText || defaultLeadText}</span>
        <span style={{ position: "relative", bottom: -6 }}>
          <Icon name="dot" />
        </span>
        <span className="sub-text" style={{ position: "relative", bottom: -2 }}>
          {subText || defaultSubText}
        </span>
      </div>
    </div>
  );
}
