import React from "react";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../components/Component";
import Lottie from "react-lottie";
import animationData from "../../animations/Lottie/animation.json";
import Head from "../../layout/head/Head";
const Error404Modern = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <>
      <Head title="Not Found" />
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <div>
            <Lottie options={defaultOptions} height={400} width={600} style={{ cursor: "default" }} />
          </div>
          <div className="wide-xs mx-auto">
            <h3 className="display-5 fw-bold nk-error-title" style={{ fontFamily: '"Rubik"' }}>
              Oops! Why you’re here?
            </h3>
            <p className="nk-error-text" style={{ fontFamily: '"Rubik"', fontSize: 18 }}>
              Sorry for the inconvenience. It seems the page was deleted or never existed..
            </p>

            <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button color="primary" size="lg" className="mt-2">
                Back To Home
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </>
  );
};
export default Error404Modern;
