
// import React from 'react'
// import { Artistdetails } from '../Podcastdata'
// import { Icon } from '../../components/Component'
// import { Button } from 'reactstrap'
// import { useNavigate } from 'react-router'
// import { getallartist } from '../../http/Get'
// import { useQuery } from '@tanstack/react-query'
// import ShimmerLandingpage from '../../Shimmer/ShimmerLandingpage'

// export default function Artistdetailspage() {
//     const navigate = useNavigate();

//     const gotoartist = (id) => {
//         navigate(`/artist/${id}`);
//     }

//     const { data: artistData, error: artistIsError, isLoading: artistIsLoading } = useQuery({
//         queryKey: ['artist'],
//         queryFn: getallartist,
//         gcTime: 0,
//         staleTime: Infinity,
//     });

//     if (artistIsLoading) return <ShimmerLandingpage />
//     if (artistIsError) return <div>Error: </div>
//     console.log(artistData);

//     return (
//         <>
//             <div className='container-fluid'>
//                 <div className="d-flex">
//                     <h5 className="py-2">Artist</h5>
//                 </div>
//                 <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
//                     {artistData?.data?.artists?.map((artist, index) => (
//                         <div className="py-2 hover-card-artist rounded-3 pointer card-opacity-artist text-center" key={artist.id}
//                             onClick={() => gotoartist(artist.id)}
//                         >
//                             <div className="position-relative d-flex flex-column align-items-center" style={{ width: '200px' }}>
//                                 <div className="card-play-button-artist position-absolute">
//                                     <Button className="btn-round btn-icon img-hover-icon" color="primary" size="lg">
//                                         <Icon name="play-fill" className="fs-2"></Icon>
//                                     </Button>
//                                 </div>
//                                 <img
//                                     src={artist.profile_image_path}
//                                     alt={artist.artist_name}
//                                     className="rounded-circle"
//                                     style={{ width: '150px', height: '150px', objectFit: 'cover' }}
//                                 />
//                             </div>
//                             <div className="py-2" style={{ width: '100px' }}>
//                                 <h6 className="text-dark" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//                                     {artist.artist_name}
//                                 </h6>
//                                 <p className='text-dark truncated-text-title'>
//                                     {artist.artist_bio}
//                                 </p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </>
//     )
// }



import React from 'react'
import { Artistdetails } from '../Podcastdata'
import { Icon } from '../../components/Component'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router'
import { getallartist } from '../../http/Get'
import { useQuery } from '@tanstack/react-query'
import ShimmerLandingpage from '../../Shimmer/ShimmerLandingpage'

export default function Artistdetailspage() {
    const navigate = useNavigate();

    const gotoartist = (id) => {
        navigate(`/artist/${id}`);
    }

    const { data: artistData, error: artistIsError, isLoading: artistIsLoading } = useQuery({
        queryKey: ['artist'],
        queryFn: getallartist,
        gcTime: 0,
        staleTime: Infinity,
    });

    if (artistIsLoading) return <ShimmerLandingpage />
    if (artistIsError) return <div>Error: </div>
    console.log(artistData);

    const handleImageError = (e) => {
        e.target.src = '../../assets/images/jsTree/img12.jpg';  
    };

    return (
        <>
            <div className='container-fluid'>
                <div className="d-flex">
                    <h5 className="py-2">Artist</h5>
                </div>
                <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
                    {artistData?.data?.artists?.map((artist, index) => (
                        <div className="py-2 hover-card-artist rounded-3 pointer card-opacity-artist text-center" key={artist.id}
                            onClick={() => gotoartist(artist.id)}
                        >
                            <div className="position-relative d-flex flex-column align-items-center" style={{ width: '200px' }}>
                                <div className="card-play-button-artist position-absolute">
                                    <Button className="btn-round btn-icon img-hover-icon" color="primary" size="lg">
                                        <Icon name="play-fill" className="fs-2"></Icon>
                                    </Button>
                                </div>
                                <img
                                    src={artist.profile_image_path}
                                    // alt={artist.artist_name}
                                    className="rounded-circle"
                                    style={{
                                        width: '150px',
                                        height: '150px',
                                        objectFit: 'cover',
                                        border: '2px solid #ccc', 
                                        backgroundColor: '#f0f0f0' 
                                    }}
                                    onError={handleImageError}
                                />
                            </div>
                            <div className="py-2" style={{ width: '100px' }}>
                                <h6 className="text-dark text-center" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center'}}>
                                    {artist.artist_name}
                                </h6>
                                <p className='text-dark truncated-text-title'>
                                    {artist.artist_bio}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}





