import { artist1, artist2, artist3, artist4, artist5, artist6 } from "../../images/Podcast/Landingpage";
export const dummyPodcast = [
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Stupe Curran",
    date: "May 5, 2021",
    title: "THE WONDERFUL MIND",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Alex Johnson",
    date: "April 20, 2021",
    title: "THE SCIENCE OF EVERYTHING",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Maria Lee",
    date: "March 15, 2021",
    title: "HISTORICAL TALES",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "James Cooper",
    date: "February 10, 2021",
    title: "MYSTERIES OF THE UNIVERSE",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Emily Stone",
    date: "January 5, 2021",
    title: "PHILOSOPHY IN EVERYDAY LIFE",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Chris Wilson",
    date: "December 25, 2020",
    title: "THE NATURE NETWORK",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Lily Evans",
    date: "November 18, 2020",
    title: "INNOVATORS OF TOMORROW",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "George Hanks",
    date: "October 10, 2020",
    title: "ANCIENT WISDOM",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Nina Garcia",
    date: "September 5, 2020",
    title: "THE HUMAN CONDITION",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "David Kim",
    date: "August 15, 2020",
    title: "BEYOND THE STARS",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Sophia Bennett",
    date: "July 30, 2020",
    title: "THE MINDS OF TOMORROW",
  },
  {
    imageUrl:
      "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg?expires=1730101275&signature=33b0092b969938ba2a6a069ea71a87433513c4d57a0c95854692ff94bf067f6a",
    author: "Henry Adams",
    date: "June 20, 2020",
    title: "CULTURE & CREATIVITY",
  },
];

export const dummyPopularData = [
  {
    id: 1,
    title: "THE WONDERFUL MIND",
    category: "Trending in Health",
    author: "Stupe Curran",
    date: "May 5, 2021",
    description:
      "Anim ullamco mollit minim esse sint commodo velit in qui cupidatat voluptate laborum amet. Ea nostrud occaecat sunt tempor proident mollit elit sit est est. Aliquip minim officia non Lorem. Culpa culpa reprehenderit in nisi aliqua irure consequat irure. Dolore consequat sunt enim sunt veniam. Ex do tempor dolore exercitation est. Eu excepteur incididunt aliqua minim magna aliquip culpa exercitation pariatur laborum et.",
    imageUrl: "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg",
    buttonIcon: "TrendingIcon",
  },
  {
    id: 2,
    title: "THE HISTORY HOUR",
    category: "Popular in Historical",
    author: "Alex Johnson",
    date: "June 1, 2021",
    description:
      "Anim ullamco mollit minim esse sint commodo velit in qui cupidatat voluptate laborum amet. Ea nostrud occaecat sunt tempor proident mollit elit sit est est. Aliquip minim officia non Lorem. Culpa culpa reprehenderit in nisi aliqua irure consequat irure. Dolore consequat sunt enim sunt veniam. Ex do tempor dolore exercitation est. Eu excepteur incididunt aliqua minim magna aliquip culpa exercitation pariatur laborum et.",
    imageUrl: "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg",
    buttonIcon: "FireIcon",
  },
  {
    id: 3,
    title: "MINDS UNLEASHED",
    category: "Trending in Philosophy",
    author: "Emma Clarke",
    date: "July 15, 2021",
    description:
      "Anim ullamco mollit minim esse sint commodo velit in qui cupidatat voluptate laborum amet. Ea nostrud occaecat sunt tempor proident mollit elit sit est est. Aliquip minim officia non Lorem. Culpa culpa reprehenderit in nisi aliqua irure consequat irure. Dolore consequat sunt enim sunt veniam. Ex do tempor dolore exercitation est. Eu excepteur incididunt aliqua minim magna aliquip culpa exercitation pariatur laborum et.",
    imageUrl: "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg",
    buttonIcon: "TrendingIcon",
  },
  {
    id: 4,
    title: "THE WELLNESS JOURNEY",
    category: "Top in Lifestyle",
    author: "Mike Thompson",
    date: "August 10, 2021",
    description:
      "Anim ullamco mollit minim esse sint commodo velit in qui cupidatat voluptate laborum amet. Ea nostrud occaecat sunt tempor proident mollit elit sit est est. Aliquip minim officia non Lorem. Culpa culpa reprehenderit in nisi aliqua irure consequat irure. Dolore consequat sunt enim sunt veniam. Ex do tempor dolore exercitation est. Eu excepteur incididunt aliqua minim magna aliquip culpa exercitation pariatur laborum et.",
    imageUrl: "https://api.silocloud.io/get-file-content/users/private/14163/podcast/27781274_7376726.jpg",
    buttonIcon: "TrendingIcon",
  },
];
export const artistsData = [
  {
    name: "Zyan Malik",
    profession: "Artist",
    imageSrc: artist1,
  },
  {
    name: "Alan Walker",
    profession: "Artist",
    imageSrc: artist2,
  },
  {
    name: "Katy Perry",
    profession: "Artist",
    imageSrc: artist3,
  },
  {
    name: "Selena Gomez",
    profession: "Artist",
    imageSrc: artist4,
  },
  {
    name: "Taylor Swift",
    profession: "Artist",
    imageSrc: artist5,
  },
  {
    name: "Ed Sheeran",
    profession: "Artist",
    imageSrc: artist6,
  },
];
export const dummyCategories = [
  { title: "Political", gradient: "linear-gradient(312deg, #ffc03c 0%, #be8000 100%)" },
  { title: "Social", gradient: "linear-gradient(312deg, #27856A 0%, #1A4A3A 100%)" },
  { title: "Environmental", gradient: "linear-gradient(312deg, #5F8109 0%, #304105 100%)" },
  { title: "Entertainment", gradient: "linear-gradient(312deg, #F037A5 0%, #7B1957 100%)" },
  { title: "Technology", gradient: "linear-gradient(312deg, #AF2896 0%, #631452 100%)" },
  { title: "Business", gradient: "linear-gradient(312deg, #477D95 0%, #2A4C57 100%)" },
  { title: "Education", gradient: "linear-gradient(312deg, #509BF5 0%, #254D7A 100%)" },
  { title: "Science", gradient: "linear-gradient(312deg, #1D3164 0%, #101B38 100%)" },
  { title: "Health", gradient: "linear-gradient(312deg, #E8115B 0%, #8B0831 100%)" },
  { title: "Sports", gradient: "linear-gradient(312deg, #E13300 0%, #701A00 100%)" },
  { title: "Lifestyle", gradient: "linear-gradient(312deg, #BA5D07 0%, #5E2F03 100%)" },
  { title: "Gaming", gradient: "linear-gradient(312deg, #5F8109 0%, #304105 100%)" },
];
export const episodeData = [
  {
    sr_no: "1",
    audio_url: "https://api.silocloud.io/podcastdata/music/LaelAlexamderSilocloud.mp3",
    Episode_name: "Tech Talk with Lael Alexander",
    category: "Technology Insights",
  },
  {
    sr_no: "2",
    audio_url: "https://api.silocloud.io/podcastdata/music/ethereal-vistas-191254.mp3",
    Episode_name: "Ethereal Vistas: Exploring Tech's Frontier",
    category: "Future of Technology",
  },
  {
    sr_no: "3",
    audio_url: "https://api.silocloud.io/podcastdata/music/family-trip-pop-summer-upbeat-song-230607.mp3",
    Episode_name: "Summer Tech Adventures: A Family Take",
    category: "Technology & Lifestyle",
  },
  {
    sr_no: "4",
    audio_url: "https://api.silocloud.io/podcastdata/music/good-night-160166.mp3",
    Episode_name: "Good Night, Tech Reflections",
    category: "Wellness & Technology",
  },
];
