import React from "react";

export default function FeaturedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={18}
      height={18}
      x={0}
      y={0}
      viewBox="0 0 469.333 469"
      style={{
        enableBackground: "new 0 0 512 512",
      }}
      xmlSpace="preserve"
      className=""
      {...props}
    >
      <g>
        <path
          d="M448 106.84h-64.813l21.399-79.809c3.05-11.37-3.691-23.082-15.082-26.133-11.39-3.05-23.106 3.711-26.133 15.082l-24.34 90.86H187.52l21.398-79.809c3.05-11.37-3.691-23.082-15.082-26.133-11.395-3.027-23.082 3.711-26.113 15.082l-24.364 90.86H64c-11.777 0-21.332 9.535-21.332 21.332 0 11.8 9.555 21.336 21.332 21.336h67.902l-34.324 128H21.332C9.559 277.508 0 287.043 0 298.84s9.559 21.332 21.332 21.332h64.813L64.746 399.98c-3.05 11.372 3.692 23.082 15.082 26.133 1.836.492 3.692.727 5.504.727 9.41 0 18.05-6.274 20.59-15.809l24.383-90.86h151.507l-21.398 79.81c-3.047 11.37 3.691 23.081 15.086 26.132 1.832.492 3.688.727 5.523.727 9.41 0 18.047-6.274 20.586-15.809l24.364-90.86h79.359c11.777 0 21.336-9.534 21.336-21.331s-9.559-21.332-21.336-21.332H337.43l34.324-128H448c11.777 0 21.332-9.535 21.332-21.336 0-11.797-9.555-21.332-21.332-21.332zM293.246 277.508H141.738l34.324-128h151.512zm0 0"
          fill="#56d30c"
          opacity={1}
          data-original="#000000"
          className=""
        />
      </g>
    </svg>
  );
}
