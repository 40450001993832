

import React from 'react';

function NewlyAddPage({ podcastData }) {
  const gotodetailpage = (id) => {
    // Function to navigate to the podcast details page
    console.log(`Navigating to podcast details with id: ${id}`); // Replace with actual navigation logic
  };

  return (
    <>
      <div className="podcast-title">Newly Added Podcasts</div>
      <div className="podcast-list"> {/* Wrapper for the podcast items */}
        {podcastData?.data?.newly_added?.map((podvalue) => (
          <div className="py-1 px-2" key={podvalue.id}>
            <div
              className="card h-250px rounded-3 border-0 pointer dark-mode-card"
              onClick={() => gotodetailpage(podvalue.id)}
            >
              <div className="position-relative card-opacity">
                <div className="card-play-button">
                  {/* <Icon name="play-circle" className="img-hover-icon" /> */}
                </div>
                <img
                  src={podvalue.image_url}
                  alt={podvalue.title} // Adding alt text for accessibility
                  className="img-responsive rounded-3"
                />
              </div>
              <div className="card-body p-0 px-1 py-1">
                <h6 className="fs-7 lato-font truncated-text fw-bold">{podvalue.title}</h6>
                {/* <h6 className="fs-7 truncated-text lato-font">{podvalue.description}</h6> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default NewlyAddPage;
