import React, { useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import "../pages/components/css/app.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { useQuery } from "@tanstack/react-query";
import { fetchPodcasts } from "../http/Post";
import ShimmerLandingpage from "../Shimmer/ShimmerLandingpage.jsx";
import { Icon } from "../components/Component.js";
import { getallartist, getAllCategories } from "../http/Get.js";
import "../pages/components/css/podcast-new.css";
import FireIcon from "../images/custom-icons/FireIcon.jsx";
import TrendingIcon from "../images/custom-icons/TrendingIcon.jsx";
import { dummyPodcast, dummyPopularData, artistsData, dummyCategories } from "./data/podcastData.js";
import samplePodcast from "../images/Podcast/Landingpage/sample-podcast.jpg";
import GenereScroll from "./Components/GenereScroll.jsx";
import { Link } from "react-router-dom";
const PodcastLandingpage = () => {
  const navigate = useNavigate();

  // const gotodetailpage = (id) => {
  //   const selectedCard = podcastcard.find(card => card.id === id);
  //   navigate('/podcast-details', { state: selectedCard });
  //   // navigate(`/podcast-details-blog/${id}`);
  //   // navigate("/podcast-details");
  // };
  const gotodetailpage = (id) => {
    navigate(`/episodes/${id}`);
  };
  const gotoartistpage = () => {
    // const selectedArtist = Artistdetails.find(artist => artist.id === id);
    // navigate('/artist', { state: selectedArtist });
    navigate(`/artist-details`);
  };
  const gotoaddpodcast = () => {
    navigate(`/add-podcast`);
  };
  const gotoupdatepodcast = () => {
    navigate(`/update-podcast/$(id)`);
  };

  const gotoartist = (id) => {
    navigate(`/artist/${id}`);
  };

  const gotoNewlyAdded = () => {
    navigate("/newadded");
  };
  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    cssEase: "linear",
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const {
    data: podcastData,
    error: podcastIsError,
    isLoading: podcastIsLoading,
  } = useQuery({
    queryKey: ["podcasts"],
    queryFn: fetchPodcasts,
    gcTime: 0,
    staleTime: Infinity,
  });
  const {
    data: artistData,
    error: artistIsError,
    isLoading: artistIsLoading,
  } = useQuery({
    queryKey: ["artist"],
    queryFn: getallartist,
    gcTime: 0,
    staleTime: Infinity,
  });
  const {
    data: category_data,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["category"],
    queryFn: getAllCategories,
    gcTime: 0,
    staleTime: Infinity,
  });

  const category_badges = category_data?.data?.categories;
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef(null);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.offsetWidth;
      containerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
      updateArrowVisibility();
    }
  };

  const updateArrowVisibility = () => {
    if (containerRef.current) {
      setShowLeftArrow(containerRef.current.scrollLeft > 0);
    }
  };
  const navigateToShowAll = () => {
    navigate("/podcast/all");
  };
  // console.log(artistData);
  if (podcastIsLoading) return <ShimmerLandingpage />;
  if (podcastIsError) return <div>Error: </div>;

  return (
    <>
      <section className="section-podcaste">
        <div className="container-fluid p-0">
          <div>
            <GenereScroll />
            <div className="rounded-2 p-3 allpodcast">
              {/* <Slider {...settings}>
                {podcastData?.data?.newly_added?.map((podvalue, index) => (
                  <div className="py-1 px-2" key={podvalue.id}>
                    <div
                      className="card h-250px rounded-3 border-0 pointer dark-mode-card"
                      onClick={() => gotodetailpage(podvalue.id)}
                    >
                      <div className="position-relative card-opacity">
                        <div className="card-play-button">
                          <Icon name="play-circle" className=" img-hover-icon"></Icon>
                        </div>
                        <img src={podvalue.image_url} alt="" className="img-responsive rounded-3" />
                      </div>
                      <div className="card-body p-0 px-1 py-1">
                        <h6 className=" fs-7 lato-font truncated-text fw-bold">{podvalue.title}</h6>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider> */}
              <div className="d-flex justify-content-between">
                <h3 className="rubik-font">Made For You</h3>
                <p className="showalltext text-dark" onClick={navigateToShowAll}>
                  Show all
                </p>
              </div>
              <div
                className="d-flex align-items-center position-relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {showLeftArrow && isHovered && (
                  <div
                    className="nk-quick-nav-icon cursor-pointer podcast-nav-slider-icon nav-left bg-light rounded-circle"
                    onClick={() => handleScroll("left")}
                  >
                    <Icon name="chevron-left" className="fs-3 text-dark" />
                  </div>
                )}
                <div
                  className="podcast-entry-container"
                  ref={containerRef}
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                  }}
                  onScroll={updateArrowVisibility}
                >
                  {dummyPodcast.slice(0, 10).map((podcast, index) => (
                    <div key={index} className="podcast-entryPoint landingpodcast me-2 border">
                      <figure className="podcast-img-parent">
                        <img src={podcast.imageUrl} alt="Podcast" className="landing-podcast-img" />
                        <div className="song-meta-details">
                          <div className="livecast-play">
                            <Icon name="play-fill" className="fs-1" />
                          </div>
                          <div className="entry-metas ms-2">
                            <span className="entry-count">{podcast.author}</span>
                            <span className="ce-date entry-meta-single fw-bold">{podcast.date}</span>
                          </div>
                        </div>
                      </figure>
                      <div>
                        <span className="entry-icon text-danger d-flex align-items-center">
                          <Icon name="book-read" className="fs-6" />
                          <small className="ms-1 fw-bold rubik-font" style={{ fontSize: "13px" }}>
                            Historical
                          </small>
                        </span>
                        <h3 className="podcast-title mt-1">{podcast.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
                {isHovered && (
                  <div
                    className="nk-quick-nav-icon cursor-pointer podcast-nav-slider-icon nav-right bg-light rounded-circle"
                    onClick={() => handleScroll("right")}
                  >
                    <Icon name="chevron-right" className="fs-3 text-dark" />
                  </div>
                )}
              </div>
              {/* <hr /> */}
              <div className="mt-3"></div>
              <div className="d-flex justify-content-between mt-3">
                <h3 className="rubik-font">Most Popular</h3>
                <p className="showalltext text-dark" onClick={navigateToShowAll}>
                  Show all
                </p>
              </div>
              <div className="row gx-1">
                {dummyPopularData.map((podcast) => (
                  <div className="col-md-6" key={podcast.id}>
                    <div className="popular-podcast-card mb-1">
                      <div className="popular-podcast-img-div">
                        <figure className="popular-podcast-img-parent">
                          <img
                            src={podcast.imageUrl}
                            alt={`${podcast.title}-image`}
                            className="popular-landing-podcast-img"
                          />
                          <div className="d-flex flex-row song-meta-details">
                            <div className="livecast-play">
                              <Icon name="play-fill" className="fs-1" />
                            </div>
                            <div className="entry-metas ms-2">
                              <span className="entry-count">{podcast.author}</span>
                              <span className="ce-date entry-meta-single fw-bold">{podcast.date}</span>
                            </div>
                          </div>
                        </figure>
                      </div>
                      <div className="popular-podcast-content d-flex flex-column">
                        <div>
                          <Button className="btn-round border shadow bg-transparent text-dark pod-info-icon">
                            {podcast.buttonIcon === "FireIcon" ? <FireIcon /> : <TrendingIcon />}
                            <span className="fw-bold ms-1">{podcast.category}</span>
                          </Button>
                          <h3 className="podcast-title popular-title mt-2">{podcast.title}</h3>
                        </div>
                        <div className="popular-podcast-para">
                          <p className="text-muted">{podcast.description}</p>
                        </div>
                        <div className="popular-podcast-navigate">
                          <p className="text-muted d-flex align-items-center">
                            View Episode
                            <Icon name="chevron-right" className="fs-6" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-3"></div>
              <div className="d-flex justify-content-between">
                <h3 className="rubik-font">Featured Artists</h3>
                <p className="showalltext text-dark">Show all</p>
              </div>
              <div className="row gx-2">
                {artistsData.map((artist, index) => (
                  <div key={index} className="col-md-2 col-sm-4 col-6 mb-2">
                    <div className="w-100 d-flex justify-content-between flex-column artist-profile-card-parent">
                      <div className="artist-profile-card p-2 position-relative text-center">
                        <div className="artist-profile-img-parent rounded-circle">
                          <img
                            src={artist.imageSrc}
                            alt="artist-profile"
                            className="img-fluid artist-profile-img rounded-circle w-100"
                          />
                        </div>
                        {/* <div className="livecast-play artist-play">
                          <Icon name="play-fill" className="fs-1" />
                        </div> */}
                      </div>
                      <div className="ps-2">
                        <h6 className="artist-profile-name m-0">{artist.name}</h6>
                        <p className="artist-profile-profession text-dark mb-2">{artist.profession}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-3"></div>
              <div className="d-flex justify-content-between">
                <h3 className="rubik-font">Browse all</h3>
                <Link to="/categories">
                  <p className="showalltext text-dark">Show all</p>
                </Link>
              </div>
              <div className="gx-1 gy-1 row">
                {category_badges &&
                  category_badges.slice(0, 12).map((category, index) => (
                    <div key={index} className="col-md-2 col-sm-4 col-6 browsing-categories">
                      <div
                        className="position-relative overflow-hidden"
                        style={{ background: category.gradient, height: "178.2px", borderRadius: "10px" }}
                      >
                        <h3 className="position-absolute browsing-categories-text">{category.name}</h3>
                        <div className="position-absolute browsing-categories-img">
                          <img src={category.category_image} alt={`${category.name} music`} />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PodcastLandingpage;
