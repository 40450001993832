import React from "react";
import PodcastHero from "./Components/PodcastHero";
import PodcastList from "./Components/PodcastList";
export default function PodcastAll() {
  return (
    <>
      <PodcastHero />
      <PodcastList />
    </>
  );
}
