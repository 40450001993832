import { getCookie } from "../utils/Utils";

export const getArtistDetails = async (artistId) => {
  const response = await fetch(`${process.env.REACT_APP_API_PODCAST}artist/get/${artistId}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
};

export const getallartist = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API_PODCAST}artist/get`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};
export const getAllCategories = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_PODCAST}categories-and-tags?only_category=true`, {
    method: "GET",
  });
  if (!response.ok) throw new Error("Failed to fetch liked list");
  return response.json();
};

export const followUser = async (artistId) => {
  const response = await fetch(`${process.env.REACT_APP_API_PODCAST}artist/follow/${artistId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", null),
    },
  });
  if (!response.ok) throw new Error("Failed to follow user");
  return response.json();
};

export const getfollowlist = async (artistId) => {
  const response = await fetch(`${process.env.REACT_APP_API_PODCAST}artist/get-follow-podcast-list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", null),
    },
  });
  if (!response.ok) throw new Error("Failed to follow user");
  return response.json();
};

export const getLikedlist = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_PODCAST}get-like-episode-list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: getCookie("authToken", null),
    },
  });
  if (!response.ok) throw new Error("Failed to fetch liked list");
  return response.json();
};
