import React, { useState } from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import Menu from "../menu/Menu";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import "react-circular-progressbar/dist/styles.css";
import { Icon } from "../../components/Component";
import UserAvatar from "../menu/UserAvatar";
import SideBarContent from "../menu/SideBarContent";
import { likedSong, grid_liked_song, add_podcast } from "../../images/Podcast/siderbar_images";
import { artist1 } from "../../images/Podcast/Landingpage";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import SidebarCompact from "../menu/SidebarCompact";
import SideBarGrid from "../menu/SideBarGrid";

const Sidebar = ({ fixed, className }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  // State to manage the current view mode (default to "list")
  const [viewMode, setViewMode] = useState("list");

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-fixed": fixed,
    "nk-sidebar-active": theme.sidebarVisibility,
    "nk-sidebar-mobile": theme.sidebarMobile,
    [`is-light`]: theme.sidebar === "white",
    [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
    [`${className}`]: className,
  });

  // Function to determine the icon based on view mode
  const getViewIcon = () => {
    switch (viewMode) {
      case "list":
        return "list"; // List view icon
      case "compact":
        return "menu"; // Compact view icon
      case "grid":
        return "grid"; // Grid view icon
      default:
        return "menu"; // Default icon
    }
  };

  // Dropdown to select view mode
  const UserSerachAndView = () => {
    return (
      <div className="row gx-1">
        <div className="form-control-wrap col-md-10 col-10">
          <div className="form-icon form-icon-left">
            <Icon name="search" />
          </div>
          <input className="form-control" type="text" id="default-2" placeholder="Search in your library" />
        </div>
        <div className="col-md-2 col-2">
          <UncontrolledDropdown>
            <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
              <Icon name={getViewIcon()} /> {/* Dynamically updates the icon */}
            </DropdownToggle>
            <DropdownMenu>
              <ul className="link-check">
                <li>
                  <span style={{ fontSize: 9 }}>View as</span>
                </li>
                <li className={viewMode === "list" ? "active" : ""}>
                  <a
                    href="#links"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setViewMode("list");
                    }}
                  >
                    <Icon name="list" />
                    List
                  </a>
                </li>
                <li className={viewMode === "compact" ? "active" : ""}>
                  <a
                    href="#links"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setViewMode("compact");
                    }}
                  >
                    <Icon name="menu" />
                    Compact
                  </a>
                </li>
                <li className={viewMode === "grid" ? "active" : ""}>
                  <a
                    href="#links"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setViewMode("grid");
                    }}
                  >
                    <Icon name="grid" />
                    Grid
                  </a>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    );
  };

  // Conditionally render sidebar content based on selected view mode
  const renderUserLibraries = () => {
    switch (viewMode) {
      case "list":
        return <UserLibrariesList />;
      case "compact":
        return <UserLibrariesCompact />;
      case "grid":
        return <UserLibrariesGrid />;
      default:
        return <UserLibrariesList />;
    }
  };

  const UserLibrariesList = () => (
    <div>
      <SideBarContent img={likedSong} leadText="Liked Songs" subText="14 items" isPinnned={true} />
      <SideBarContent
        img={add_podcast}
        leadText="Create Podcast"
        subText="3 items"
        isPinnned={false}
        isFeatured={true}
      />
      <SideBarContent leadText="My First playlist" subText="14 items" isPinnned={false} />
      <SideBarContent img={artist1} leadText="Zyan Malik" subText="Artist" isPinnned={false} avatarShape="circle" />
    </div>
  );

  const UserLibrariesCompact = () => (
    <div className="mt-1">
      <SidebarCompact leadText="Liked Songs" subText="14 items" isPinnned={true} />
      <SidebarCompact leadText="Create Podcast" subText="3 items" isPinnned={true} isFeatured={true} />
      <SidebarCompact leadText="My First playlist" subText="14 items" isPinnned={true} />
      <SidebarCompact leadText="Zyan Malik" subText="Artist" isPinnned={false} />
    </div>
  );

  const UserLibrariesGrid = () => (
    <div className="mt-1 row gx-1 gy-1">
      <SideBarGrid img={grid_liked_song} />
      <SideBarGrid img={add_podcast} isFeatured />
      <SideBarGrid />
      <SideBarGrid img={artist1} circular />
    </div>
  );

  return (
    <>
      <div className={classes} style={{ zIndex: 999 }}>
        <SimpleBar className="nk-sidebar-inner">
          <UserSerachAndView />
          {/* Render the content based on view mode */}
          {renderUserLibraries()}
        </SimpleBar>
      </div>
      {theme.sidebarVisibility && <div onClick={themeUpdate.sidebarVisibility} className="nk-sidebar-overlay"></div>}
    </>
  );
};

export default Sidebar;
