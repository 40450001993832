import React from "react";
import { UserAvatar } from "../../../components/Component";
import { artist1, artist2, artist3, artist4, artist5 } from "../../../images/Podcast/Landingpage";
import { FaHeart, FaPlay } from "react-icons/fa";
import { BsFillPinAngleFill } from "react-icons/bs";

const topPodcastsData = [
  {
    id: 1,
    title: "The Daily News",
    host: "Michael Barbaro",
    studio: "The New York Times",
    duration: "25:00",
    likes: "1,500,326",
    image: artist1,
    isPinned: true,
    isLiked: true,
  },
  {
    id: 2,
    title: "Science Vs",
    host: "Wendy Zukerman",
    studio: "Gimlet Media",
    duration: "35:20",
    likes: "820,654",
    image: artist2,
    isPinned: false,
    isLiked: false,
  },
  {
    id: 3,
    title: "How I Built This",
    host: "Guy Raz",
    studio: "NPR",
    duration: "45:10",
    likes: "1,200,789",
    image: artist3,
    isPinned: true,
    isLiked: false,
  },
  {
    id: 4,
    title: "TED Talks Daily",
    host: "Various Hosts",
    studio: "TED",
    duration: "15:30",
    likes: "2,104,876",
    image: artist4,
    isPinned: false,
    isLiked: true,
  },
  {
    id: 5,
    title: "Crime Junkie",
    host: "Ashley Flowers & Brit Prawat",
    studio: "Audiochuck",
    duration: "50:45",
    likes: "3,250,982",
    image: artist5,
    isPinned: false,
    isLiked: false,
  },
];

export default function TopSongs() {
  return (
    <div className="top-songs-parent ps-4 pe-4">
      <h3 className="top-songs-tag">Top Podcast</h3>
      <div className="mt-2">
        {topPodcastsData.map((song) => (
          <div key={song.id} className="top-song-card shadow d-flex justify-content-between p-1 mb-1 cursor-pointer">
            <div className="d-flex align-items-center top-song-width justify-content-start position-relative">
              <div className="avatar-overlay-container">
                <UserAvatar theme="primary" className="sq md" image={song.image}></UserAvatar>
                <div className="overlay">
                  <FaPlay className="avatar-overlay-play-button" color="#fff" />
                </div>
              </div>
              <small className="ms-2 fw-bold text-dark">{song.id.toString().padStart(2, "0")}</small>
              <div className="ms-2">
                <FaHeart color={`${song.isLiked ? "red" : "currentcolor"}`} />
              </div>
            </div>
            <div className="d-flex align-items-center top-song-width">
              <span className="text-dark top-song-name">
                {song.title}.{song.host}
              </span>
            </div>
            <div className="d-flex align-items-center top-song-width">
              <span className="text-dark top-song-name">{song.studio}</span>
            </div>
            <div className="d-flex align-items-center top-song-width d-none d-md-block align-content-center">
              <span className="text-dark top-song-name">{song.duration}</span>
            </div>
            <div className="d-flex align-items-center top-song-width d-none d-md-block align-content-center">
              <span className="text-dark top-song-name">{song.likes}</span>
            </div>
            <div className="d-flex align-items-center top-song-width justify-content-end">
              <BsFillPinAngleFill className="me-2" size={20} color={`${song.isPinned ? "#1BC558" : "currentcolor"}`} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
