// import React, { useState } from 'react';

// const StudioForm = () => {
//   const [channelName, setChannelName] = useState('');
//   const [logo, setLogo] = useState(null);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Channel Name:', channelName);
//     console.log('Logo:', logo);
//   };

//   const handleLogoChange = (e) => {
//     setLogo(e.target.files[0]); 
//   };

//   return (
//     <div className="container-fluid mt-5">
//       <h2>Create Your Radio Channel</h2>
//       <form onSubmit={handleSubmit}>
//         <div className="mb-3">
//           <label htmlFor="channelName" className="form-label">
//             Channel Name
//           </label>
//           <input
//             type="text"
//             className="form-control"
//             id="channelName"
//             value={channelName}
//             onChange={(e) => setChannelName(e.target.value)}
//             required
//           />
//         </div>
//         <div className="mb-3">
//           <label htmlFor="logo" className="form-label">
//             Channel Logo
//           </label>
//           <input
//             type="file"
//             className="form-control"
//             id="logo"
//             accept="image/*"
//             onChange={handleLogoChange}
//             required
//           />
//         </div> 
//         <div className='d-flex justify-content-end'>
//         <button type="submit" className="btn btn-primary">
//           Next
//         </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default StudioForm;





import React, { useState, useCallback } from 'react';
import Dropzone from 'react-dropzone'; 
import { Button } from 'reactstrap'; 

const StudioForm = () => {
  const [channelName, setChannelName] = useState('');
  const [files2, setFiles2] = useState([]); 

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Channel Name:', channelName);
    console.log('Uploaded Files:', files2);
  };

  const handleFileChange = useCallback((acceptedFiles) => {
    setFiles2(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file), 
        })
      )
    );
  }, []);

  const removeFile = (index) => {
    const newFiles = [...files2];
    newFiles.splice(index, 1);
    setFiles2(newFiles); 
  };

  return (
    <div className="container-fluid mt-5">
      <h2>Create Your Radio Channel</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="channelName" className="form-label">
            Channel Name
          </label>
          <input
            type="text"
            className="form-control"
            id="channelName"
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
            placeholder="Enter channel name"
            required
          />
        </div>

        <div className="col-md-6 ps-md-3">
          <label className="form-label">Channel Logo</label>
          <Dropzone onDrop={handleFileChange} maxFiles={1}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  className="dropzone upload-zone dz-clickable text-center"
                  style={{ border: '2px dashed #007bff', padding: '15px' }}
                >
                  <input {...getInputProps()} />
                  {files2.length === 0 ? (
                    <div className="dz-message">
                      <span className="dz-message-text">Drag and drop file here</span>
                      <span className="dz-message-or">or</span>
                      <Button color="primary">SELECT</Button>
                    </div>
                  ) : (
                    files2.map((file, index) => (
                      <div
                        key={file.name}
                        className="dz-preview dz-processing dz-image-preview dz-complete"
                      >
                        <div className="dz-image">
                          <img
                            src={file.preview}
                            alt="preview"
                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                          />
                        </div>
                        <Button
                          onClick={() => removeFile(index)}
                          className="close-button"
                          color="danger"
                          style={{ marginTop: '10px' }}
                        >
                          Remove
                        </Button>
                      </div>
                    ))
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <button type="submit" className="btn btn-primary">
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default StudioForm;

