import React, { useState, useEffect } from "react";
import img from "../../assets/images/jsTree/LikedHeart.png";
import Icon from "../../components/icon/Icon";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import "../../Podcast-pages/style.css";
import { getLikedlist } from '../../http/Get';
import { useQuery } from '@tanstack/react-query';

function LikedPage() {
    const { data: LikedData, isLoading } = useQuery({
        queryKey: ["getLiked"],
        queryFn: async () => getLikedlist(),
        gcTime: 0,
        staleTime: Infinity,
    });
    console.log(LikedData, "LikedData");

    const [isFollowed, setIsFollowed] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [showNotification, setShowNotification] = useState(false); // State for notification

    useEffect(() => {
        // Check if there are no liked episodes
        if (!isLoading && (!LikedData || !LikedData.data || LikedData.data.length === 0)) {
            setShowNotification(true);
        } else {
            setShowNotification(false); // Hide notification if there are liked episodes
        }
    }, [LikedData, isLoading]); // Dependency array to run effect when LikedData or isLoading changes

    const toggleFollow = () => setIsFollowed(!isFollowed);
    const toggleplay = () => setIsPlay(!isPlay);

    const handleShare = (id) => {
        console.log("Share podcast", id);
    };

    const containerStyle = {
        width: "100%",
        padding: "5px",
    };

    const formatDuration = (duration) => {
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <div className="container-fluid" style={containerStyle}>
            {showNotification && (
                <div className="alert alert-warning" role="alert">
                    You have not liked any episodes yet!
                </div>
            )}
            <div className="row p-3 pb-3 d-flex bg-light">
                <div className="col-lg-4 col-md-5 d-flex justify-content-center align-items-center">
                    <img
                        src={img}
                        alt="Podcast Cover"
                        className="rounded-3 img-fluid"
                        style={{ height: "250px", width: "80%", objectFit: "cover" }}
                    />
                </div>
                <div className="col-lg-8 col-md-7 py-3 py-md-0 d-flex flex-column justify-content-center">
                    <h1 className="text-danger fw-bolder">Liked Songs</h1>
                    <p className="text-muted">Your favorite songs collection</p>
                    <p>#music #podcast #playlist</p>
                    <p>Category: Music</p>
                    <div className="d-flex gap-4 justify-content-center justify-content-md-start">
                        <div>
                            <button className="btn px-3 bg-white text-danger" onClick={toggleFollow}>
                                {isFollowed ? <Icon name="check" /> : <Icon name="plus-sm" />}
                            </button>
                        </div>
                        <div>
                            <button className="btn text-white bg-danger" onClick={toggleplay}>
                                {isPlay ? "Pause" : "Play"}
                            </button>
                        </div>
                        <div className="dropend sharepodcast">
                            <UncontrolledDropdown>
                                <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0 hover-black">
                                    <Icon name="more-v" />
                                </DropdownToggle>
                                <DropdownMenu className="p-0">
                                    <ul className="link-list-opt no-bdr p-0">
                                        <li>
                                            <DropdownItem onClick={() => handleShare("liked-podcast")}>
                                                <Icon name="share-fill" />
                                                <span>Share</span>
                                            </DropdownItem>
                                        </li>
                                    </ul>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row h-130 p-3 mt-3 d-flex flex-column">
                <div className="col-12 table-responsive d-flex justify-content-center">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : ( 
                        <table className="recentlysong" style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: "8px" }}>SrNo</th>
                                    <th style={{ padding: "8px" }}>Image</th>
                                    <th style={{ padding: "8px" }}>Title</th>
                                    <th style={{ padding: "8px" }}>Added Date</th>
                                    <th className="duration-column" style={{ padding: "8px" }}>
                                        Duration
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {LikedData && LikedData.data && LikedData.data.length > 0 ? (
                                    LikedData.data.map((like) => (
                                        <tr key={like.sr_no}>
                                            <td style={{ padding: "8px" }}>{like.sr_no}</td>
                                            <td style={{ padding: "8px" }}>
                                                <img
                                                    src={like.image_url}
                                                    alt={like.title}
                                                    style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                                />
                                            </td>
                                            <td style={{ padding: "8px" }}>{like.title}</td>
                                            <td style={{ padding: "8px" }}>{like.created_at}</td>
                                            <td className="duration-column" style={{ padding: "8px" }}>
                                                {formatDuration(like.duration)}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} style={{ padding: "8px", textAlign: "center" }}>No liked episodes found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LikedPage;




