import { getCookie } from "../utils/Utils";

export const   fetchPodcasts = async ({ id = null, type = 0 }) => {
    console.log(id);
    const formData = new FormData();
    console.log("type id = ", type, id);
    formData.append("type", type);
    formData.append("podcast_id", id);

    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}get-podcast`, {
        method: "POST",
        body: formData,
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};

export const fetchPodcastsbyId = async ({ id }) => {
    // console.log(id);
    // const formData = new FormData();
    // console.log("type id = ", type, id);
    // formData.append("type", type);
    // formData.append("podcast_id", id);

    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}get-podcast/${id}`, {
        method: "GET",
        headers: {
            authToken: getCookie("authToken", null),
        },

    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};

export const fetchCategoriesAndTags = async (categoryId = null) => {
    console.log('Fetching categories and tags', categoryId);
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}categories-and-tags?category_id=${categoryId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch categories and tags');
    }
    return response.json();
};
export const languageOfpodcast = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}languages`);
    if (!response.ok) {
        throw new Error('Failed to fetch categories and tags');
    }
    return response.json();
};

export const addPodcastApi = async (data) => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}add-podcast`, {
        method: 'POST',
        headers: {
            authToken: getCookie("authToken", null),
        },
        body: data,
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add podcast.');
    }
    return response.json();
};

export const updatePodcastApi = async ({ updatedData }) => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}update-podcast`, {
        method: 'POST',
        headers: {
            authToken: getCookie("authToken", null),
        },
        body: updatedData, // Pass FormData directly
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update podcast.');
    }

    return response.json(); // Return response data
};

export const deletePodcastApi = async (podcastId) => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}delete-podcast/${podcastId}`, {
        method: 'DELETE',
        headers: {
            authToken: getCookie("authToken", null),
        },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete podcast.');
    }

    return response.json();
};

export const getUserPodcast = async () => {
    // const formData = new FormData();
    // console.log("type id = ", type, id);
    // formData.append("type", type);
    // formData.append("podcast_id", id);

    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}user/podcasts`, {
        method: "GET",
        headers: {
            authToken: getCookie("authToken", null),
        },

    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};
export const deletepodcastuser = async (id) => {
    const formData = new FormData();
    console.log("id = ", typeof id);
    formData.append("podcast_id", id);

    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}delete-podcast`, {
        method: "POST",
        headers: {
            authToken: getCookie("authToken", null),
        },
        body: formData,

    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};
export const addEpisode = async (episodeData) => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}add-episode`, {
        method: "POST",
        headers: {
            authToken: getCookie("authToken", null),
        },
        body: episodeData,

    });

    if (!response.ok) {
        throw new Error("Error adding episode");
    }

    return response.json();
};
export const deleteEpisode = async (id) => {
    const formData = new FormData();
    console.log("id = ", typeof id);
    formData.append("episode_id", id);

    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}delete-episode`, {
        method: "POST",
        headers: {
            authToken: getCookie("authToken", null),
        },
        body: formData,

    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};
export const updateepisodebyid = async ({ updatedData }) => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}update-episode`, {
        method: 'POST',
        headers: {
            authToken: getCookie("authToken", null),
        },
        body: updatedData, // Pass FormData directly
    });


    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update podcast.');
    }
    // const d = await response.json();
    // console.log(d);
    return response.json();
};

export const getepisodebyid = async ({ id }) => {
    // console.log(id);
    // const formData = new FormData();
    // console.log("type id = ", type, id);
    // formData.append("type", type);
    // formData.append("podcast_id", id);

    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}get-episode/${id}`, {
        method: "GET",
        headers: {
            authToken: getCookie("authToken", null),
        },
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};

// new 
export const getArtistDetails = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}artist/get/${id}`, {  
        method: "GET",
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};



export const getLikedepisode = async ({ id }) => {

    const formData = new FormData();
    formData.append("episodes_id", id)
    const response = await fetch(`${process.env.REACT_APP_API_PODCAST}like-episode`, {
        method: 'POST',
        headers: {
            'authToken': getCookie("authToken", null),
        },
        body: formData
    });
    if (!response.ok) throw new Error('Failed to fetch liked list');
    return response.json();
};



