import React, { useRef } from "react";
import { Icon } from "../../components/Component";
import { getAllCategories } from "../../http/Get";
import { useQuery } from "@tanstack/react-query";

export default function GenereScroll() {
  const {
    data: category_data,
    isLoading: category_isLoading,
    error: category_error,
    isError,
  } = useQuery({
    queryKey: ["category"],
    queryFn: getAllCategories,
    gcTime: 0,
    staleTime: Infinity,
  });
  const category_badges = category_data?.data?.categories;
  const scrollRef = useRef(null);
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };
  return (
    <div className="p-3 d-flex justify-content-between align-items-center">
      <div className="nk-quick-nav-icon cursor-pointer" onClick={scrollLeft}>
        <Icon name="chevron-left" className="fs-3" />
      </div>
      <div
        ref={scrollRef}
        className="d-flex overflow-auto category-scrollbar"
        style={{ gap: "1rem", whiteSpace: "nowrap" }}
      >
        {category_badges &&
          category_badges.map((category) => (
            <span
              key={category.id}
              className="align-items-center categories-badges cursor-pointer d-flex fw-bold justify-center pe-1 ps-1"
            >
              {category.name}
            </span>
          ))}
      </div>
      <div className="nk-quick-nav-icon cursor-pointer" onClick={scrollRight}>
        <Icon name="chevron-right" className="fs-3" />
      </div>
    </div>
  );
}
