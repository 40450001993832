import React from "react";
import "../../pages/components/css/podcast-new.css";
import { BsFillPinAngleFill } from "react-icons/bs";
import { FaPlay, FaPlus } from "react-icons/fa";
import { default_sidebar } from "../../images/Podcast/siderbar_images";
import { Badge } from "reactstrap";
export default function SideBarContent({ img, leadText, subText, avatarShape, isPinnned, isFeatured }) {
  const defaultImg = default_sidebar;
  const defaultLeadText = "John";
  const defaultSubText = "John@doe.com";
  const defaultAvatarShape = "10%";

  return (
    <div className="mt-1 p-0 user-card sidebarUserCards" style={{ cursor: "pointer", height: 60, borderRadius: 5 }}>
      <div style={{ position: "relative" }}>
        <img
          src={img || defaultImg}
          alt="User Avatar"
          className="border-0 img-fluid ml-2"
          style={{
            width: 50,
            height: 50,
            borderRadius: avatarShape === "circle" ? "50%" : defaultAvatarShape,
            objectFit: "cover",
            marginLeft: 2,
            border: "1px solid black",
          }}
        />
        <div
          className="hover-overlay"
          style={{
            borderRadius: avatarShape === "circle" ? "50%" : defaultAvatarShape,
          }}
        >
          {/* Conditionally render FaPlus if isFeatured is true, otherwise FaPlay */}
          {isFeatured ? <FaPlus className="play-icon" /> : <FaPlay className="play-icon" />}
        </div>
      </div>
      <div className="ms-1 user-info">
        <span className="lead-text">{leadText || defaultLeadText}</span>
        <span className="sub-text">
          {/* Conditionally render Featured badge if isFeatured is true */}
          {isFeatured ? (
            <Badge className="badge-dim" color="primary">
              Featured
            </Badge>
          ) : (
            <>
              {isPinnned && <BsFillPinAngleFill color="#1BC558" />}
              <span className={`${isPinnned ? "ms-1" : ""}`}>{subText || defaultSubText}</span>
            </>
          )}
        </span>
      </div>
    </div>
  );
}
