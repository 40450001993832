import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export default function BreadCrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumb className="breadcrumb-arrow">
      <BreadcrumbItem>
        <Link to="/">Home</Link>
      </BreadcrumbItem>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return (
          <BreadcrumbItem key={name} active={isLast}>
            {isLast ? (
              name.charAt(0).toUpperCase() + name.slice(1)
            ) : (
              <Link to={routeTo}>{name.charAt(0).toUpperCase() + name.slice(1)}</Link>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}
