import React from "react";
import { default_sidebar } from "../../images/Podcast/siderbar_images";
import { FaPlay, FaPlus } from "react-icons/fa"; // Import the FaPlus icon

export default function SideBarGrid({ img, toolTipText, circular = false, isFeatured = false }) {
  const defaultImg = default_sidebar;

  return (
    <div className="col-md-6 sideBarGridCard">
      <div className="gridSideBarImage">
        <img src={img || defaultImg} alt="" className={`img-fluid ${circular ? "rounded-circle" : "rounded-2"}`} />

        <div
          className="gridSideBarImageOverlay"
          style={{
            borderRadius: circular ? "50%" : "",
          }}
        >
          {isFeatured ? <FaPlus className="play-icon" /> : <FaPlay className="play-icon" />}
        </div>
      </div>
    </div>
  );
}
