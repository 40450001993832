import React, { useState } from "react";
import { Badge, Button } from "reactstrap";
import { Icon } from "../../../components/Component";

export default function ProfileArtist() {
  const [isFollowing, setIsFollowing] = useState(false);

  const handleToggle = () => {
    setIsFollowing(!isFollowing);
  };
  return (
    <>
      <section className="artist-header-profile">
        <div className="artist-profile-meta">
          <div className="artist-profile-picture shadow-lg"></div>
          <div className="artist-profile-podcaster-name">
            <div className="ms-2  artist-profile-podcaster-name-text">
              <h1 className="">Alan walker</h1>
              <span className="fw-bold" style={{ letterSpacing: 1 }}>
                <span style={{ letterSpacing: 1 }} className="fw-bold">
                  38,52,000
                </span>{" "}
                monthly listeners
              </span>
            </div>
            <div className="ms-2 artist-following-button d-flex">
              <Button className="btn-round" color="primary" size="lg" onClick={handleToggle}>
                <Icon name={isFollowing ? "user-check" : "user-add"} className="me-1" />
                {isFollowing ? "Followed" : "Follow"}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
