import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LayoutSidebar from "../layout/index-sidebar";
import PodcastHome from "../pages/PodcastHome";
// import Podcastdetails from "../Podcast-pages/Podcastdetails";
import AudioPlayerMusic from "../Podcast-pages/AudioPlayerMusic";
import Podcastdetails from "../Podcast-pages/Podcastdetails";
import Addpodcast from "../Podcast-pages/add-podcast/Addpodcast";
import Demo from "../Podcast-pages/Demo";
import AddPodcast from "../Podcast-pages/add-podcast/Addpodcast";
import Podcastprofile from "../Podcast-pages/Podcast-profile/Podcastprofile";
import UpdatePodcastnew from "../Podcast-pages/add-podcast/Updatenewcode";
import Addepisode from "../Podcast-pages/Podcast-Episode/Addepisode";
import RandomGradientDiv from "../Podcast-pages/Podcast-Episode/Demo";
import Updateepisode from "../Podcast-pages/Podcast-Episode/Updateepisode";
import Artistdetailspage from "../Podcast-pages/Artist-pages/Artistdetailspage";
import LikedPage from "../layout/menu/LikedPage";
import Artist from "../Podcast-pages/Artist-pages/Artist";
import Studio from "../layout/menu/Studio";
import NewlyAddPage from "../Podcast-pages/NewlyAddPage";
import PodcastAll from "../Podcast-pages/PodcastAll";
import BrowseCategories from "../Podcast-pages/BrowseCategories";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import Error404Modern from "../pages/error/404-modern";
import ArtistPage from "../Podcast-pages/Artist/ArtistPage";
const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutSidebar />}>
        <Route index element={<PodcastHome />} />
        <Route path="profile/episodes/:id" element={<Podcastdetails />} />
        <Route path="podcast/:type?" element={<PodcastAll />}></Route>
        <Route path="/episodes/:id" element={<Podcastdetails />} />
        {/* <Route path="/artist/:id" element={<Artist />} /> */}
        <Route path="/artist/:id?" element={<ArtistPage />} />
        <Route path="/add-podcast" element={<Addpodcast />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/LikedPage" element={<LikedPage />} />
        <Route path="/Studio" element={<Studio />} />
        <Route path="/categories" element={<BrowseCategories />} />
        {/* <Route path="/App1" element={<App1 />} /> */}
        {/* <Route path="/podcast" element={<Podcastdetails />} /> */}
        <Route path="/add-episode/:episodeid" element={<Addepisode />} />
        <Route path="/update-episode/:episodeid" element={<Updateepisode />} />
        <Route path="/artist-details" element={<Artistdetailspage />} />
        <Route path="/newadded" element={<NewlyAddPage />} />
        {/* <Route path="/artist/:id" element={< ArtistDetails/>} /> */}
        <Route path="/add-podcast" element={<AddPodcast />} />
        <Route path="/update-podcast/:id" element={<UpdatePodcastnew />} />
        <Route path="/profile" element={<Podcastprofile />} />
        <Route path="/demo" element={<RandomGradientDiv />} />
        <Route path="/" element={<AudioPlayerMusic />} />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
