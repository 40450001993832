import React from "react";
import { getAllCategories } from "../http/Get";
import { useQuery } from "@tanstack/react-query";
import BreadCrumbs from "./Components/Breadcrumbs/BreadCrumbs";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
export default function BrowseCategories() {
  const {
    data: category_data,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["category"],
    queryFn: getAllCategories,
    gcTime: 0,
    staleTime: Infinity,
  });

  const category_badges = category_data?.data?.categories;
  if (isLoading) {
    return <ShimmerSimpleGallery card imageHeight={178} row={6} col={4} />;
  }
  return (
    <section className="section-podcaste">
      <div className="container-fluid p-0">
        <div>
          {/* <GenereScroll /> */}
          <div className="rounded-2 p-3 allpodcast">
            <div className="d-flex justify-content-between">
              <h3 className="rubik-font">Choose from categories</h3>
              <div>
                <BreadCrumbs />
              </div>
            </div>
            <div className="gx-1 gy-1 row mt-1">
              {category_badges &&
                category_badges.map((category, index) => (
                  <div
                    key={index}
                    className={`${
                      [2, 12, 10, 18].includes(category.id) ? "col-md-4" : "col-md-2"
                    } col-sm-4 col-6 browsing-categories`}
                  >
                    <div
                      className="position-relative overflow-hidden"
                      style={{
                        background: category.gradient,
                        height: "178.2px",
                        borderRadius: "10px",
                      }}
                    >
                      <h3 className="position-absolute browsing-categories-text">{category.name}</h3>
                      <div
                        className={`position-absolute ${
                          [2, 12, 10, 18].includes(category.id)
                            ? "browsing-categories-img-random"
                            : "browsing-categories-img"
                        } `}
                      >
                        <img src={category.category_image} alt={`${category.name} music`} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
