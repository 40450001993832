import React from "react";
import arjitimage from "../assets/images/jsTree/arijit-singh.jpg";
import { Icon } from "../components/Component";
export default function Demo() {
  return (
    <>
     <div className="container-fluid py-3 bg-black">
        <div className="row bg-backgound justify-content-center align-item-center ">
          <div className="col-md-6 d-lg-none d-flex text-center align-item-center d-flex justify-content-center">
         <img src={arjitimage} alt="" className="border-radius-50 img-responsive " />
          </div>
        </div>
        <div className="row  bg-black justify-content-center">
          <div className="col-md-3 d-lg-flex d-none">
            <img src={arjitimage} alt="" className="border-radius-50 img-responsive img-position "  />
          </div>
          <div className="col-lg-6 ">
              <h4 className="heading-1 text-white">Arjit Singh</h4>
              <div className="col-lg-6 col-md-3">
              <p id=""><img src="https://flagsapi.com/IN/flat/16.png" alt="Indian flag"/>Indian</p>
              </div>
            <div className="d-flex justify-content-end">
              <div >
                <button type="button" className=" btn btn-warning mx-2">
                  Following
                </button>
                <Icon name="upload" id=""></Icon>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column">
            <p>Arijit Singh is an Indian playback singer and music composer.</p>
            <p>The recipient of several accolades including two</p>
            <p>National Film Awards and seven Filmfare Awards, he has</p>
            <p>recorded songs in several Indian languages, predominantly Hindi and Bengali.</p>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
}
