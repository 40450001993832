import React from "react";

export default function TrendingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={20}
      height={20}
      x={0}
      y={0}
      viewBox="0 0 68 68"
      style={{
        enableBackground: "new 0 0 512 512",
      }}
      xmlSpace="preserve"
      className=""
      {...props}
    >
      <g>
        <path
          fill="#f14f4a"
          d="m65.67 16.384-1.263 12.161c-.146 1.335-1.783 1.909-2.722.97l-2.983-2.983-18.784 18.784c-1.178 1.179-3.254 1.179-4.432 0L25.14 34.97 7.66 52.46c-.606.605-1.409.918-2.212.918s-1.606-.313-2.21-.917a3.141 3.141 0 0 1 0-4.433l19.69-19.691c1.179-1.179 3.255-1.179 4.423 0l10.356 10.346L54.28 22.11l-3.494-3.494c-.949-.949-.375-2.586.96-2.722l12.16-1.262a1.596 1.596 0 0 1 1.763 1.752z"
          opacity={1}
          data-original="#f14f4a"
          className=""
        />
      </g>
    </svg>
  );
}
