import React from "react";

import { Icon, Progress, UserAvatar } from "../../components/Component";
import samplePodcast from "../../images/custom-icons/Sample_featured.jpg";
import { Button } from "react-bootstrap";
import FeaturedIcon from "../../images/custom-icons/FeaturedIcon.jsx";
export default function PodcastHero({
  heroIcon,
  heroText,
  podcastImage,
  podcastTitle,
  podcastDescription,
  username,
  profile_image,
}) {
  return (
    <section className="featured-podcast">
      <div className="container-fluid p-0">
        <div className="col-xxl-12 px-4 ">
          <div className="align-items-center flex-lg-row-reverse g-5 py-5 row featured-podcast">
            <div className="col-lg-6">
              <div>
                <Button className="btn-round border shadow bg-transparent text-dark pod-info-icon mb-1">
                  <FeaturedIcon />
                  <span className="fw-bold ms-1">Featured Episode</span>
                </Button>
              </div>
              <div>
                <h1 className="display-5 fw-bold lh-1 mb-3 featured-text">The daily life With Me and Myself!</h1>
                <div className="mt-1">
                  <div className="user-card fetured-card">
                    <UserAvatar image={samplePodcast}></UserAvatar>
                    <div className="user-info">
                      <span className="lead-text">Dustin Mock</span>
                      <span className="sub-text">mock@softnio.com</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-1">
                <p className="featured-text-para">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. In temporibus alias tenetur natus fugit
                  atque eveniet fuga, ducimus omnis optio reiciendis, commodi eaque veniam nobis est et dolores a eos?
                  Numquam, id facilis maxime aliquam quia voluptates obcaecati nulla assumenda beatae unde tempore sint
                  nemo vero dolorem fuga? Consectetur, nostrum.
                </p>
              </div>
            </div>
            <div className="col-10 col-sm-8 col-lg-6 position-relative featured-image-block text-center">
              <img
                src={samplePodcast}
                className="mx-lg-auto img-fluid shadow rounded-circle"
                alt="Bootstrap Themes"
                width={500}
                height={300}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
